<template>
  <div class="table-responsive p-5">
    <table class="table placeholder-wave">
      <thead>
        <tr>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
          <th scope="col">
            <span class="placeholder col-6"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <span class="placeholder col-4 bg-secondary"></span>
          </th>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <span class="placeholder col-4 bg-secondary"></span>
          </th>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <span class="placeholder col-4 bg-secondary"></span>
          </th>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
          <td>
            <span class="placeholder col-4 bg-secondary"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
