
import { defineComponent, onMounted } from "vue";
import { SupplierSetup } from "./Responses";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useTimeAgo } from "@vueuse/core";

import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import AddComment from "@/components/widgets/comments/Widget1.vue";
import CommentList from "@/components/widgets/comments/Widget2.vue";


export default defineComponent({
  name: "Supplier View",
  components: {
    AgGridFP,
    AddComment,
    CommentList,
    TableSkeleton,
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const {
      handleAddComment,
      currentUser,
      handleTimeAgo,
      sortedComments,
      rfqComments,
      rfqRows,
      rfqNo,
      translate,
      rfqClientName,
      rfqClientContact,
      rfqClientEmail,
      rfqDescription,
      handleSupplierFormAccept,
      handlesupplierFormDecline,
      validationSchema,
      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      rfqDocs,
      suppliersData,
      selectedUsers,
      initialValues
    } = SupplierSetup();

    return {
      handleAddComment,
      currentUser,
      handleTimeAgo,
      useTimeAgo,
      sortedComments,
      rfqComments,
      rfqRows,
      rfqNo,
      rfqClientName,
      rfqClientContact,
      rfqClientEmail,
      translate,
      rfqDescription,
      handleSupplierFormAccept,
      handlesupplierFormDecline,
      validationSchema,
      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      rfqDocs,
      suppliersData,
      selectedUsers,
      initialValues
    }
  }

});
