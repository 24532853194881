
import { defineComponent, onMounted, ref } from "vue";
import Quill from "quill/dist/quill.js";

export default defineComponent({
  name: "widget-1",
  emits: ['add-comment'],
  props: {
    widgetClasses: String,
    userId: String,
    userName: String,
  },
  setup(props, { emit }) {
    const quill = ref();

    const emitComment = () => {
      const delta = quill.value.getContents();
      const comment = {
        "comment_id": Math.random().toString(36).substring(2),
        "from": {
          "user_id": props.userId,
          "name": props.userName
        },
        "comments": delta.ops,
        "created_at": new Date(Date.now()).toISOString()
      }

      emit('add-comment', comment)
      quill.value.setContents([{ insert: '\n' }]);
    }

    onMounted(() => {
      const editorId = "kt_forms_widget_1_editor";

      // init editor
      const options: object = {
        modules: {
          toolbar: {
            container: "#kt_forms_widget_1_editor_toolbar"
          }
        },
        placeholder: 'Add a comment...',
        theme: "snow"
      };

      // Init editor
      quill.value = new Quill("#" + editorId, options);
    });

    return {
      emitComment
    }
  }
});
