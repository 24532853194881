
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import Quill from "quill/dist/quill.js";

export default defineComponent({
  name: "widget-3",
  props: {
    widgetClasses: String,
    userName: String,
    createdAt: String,
    updatedAt: String,
    comments: Array
  },
  setup(props) {
    const editorId = "comment_body";
    const quill = ref();

    onMounted(() => {
      quill.value = new Quill("#" + editorId);
      console.log(props.comments)
      quill.value.setContents(props.comments, 'user')
      quill.value.enable(false);
    })
  }
});
