<template>
  <!--begin::Reply-->
  <div class="d-flex mb-5" :class="widgetClasses">
    <!--begin::Avatar-->
    <div class="symbol symbol-45px me-5">
      <img src="media/avatars/blank.png" />
    </div>
    <!--end::Avatar-->

    <!--begin::Info-->
    <div class="d-flex flex-column flex-row-fluid">
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mb-1">
        <a href="#" class="text-gray-800 text-hover-primary fw-bolder me-2">{{ userName || null }}</a>

        <span class="text-gray-400 fw-bold fs-7 text-capitalize">{{ createdAt || null }}</span>

        <!-- <a href="#" class="ms-auto text-gray-400 text-hover-primary fw-bold fs-7">Reply</a> -->
      </div>
      <!--end::Info-->

      <!--begin::Post-->
      <span id="comment_body" class="text-gray-800 fs-7 fw-normal pt-1"></span>
      <!--end::Post-->
    </div>
    <!--end::Info-->
  </div>
  <!--end::Reply-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import Quill from "quill/dist/quill.js";

export default defineComponent({
  name: "widget-3",
  props: {
    widgetClasses: String,
    userName: String,
    createdAt: String,
    updatedAt: String,
    comments: Array
  },
  setup(props) {
    const editorId = "comment_body";
    const quill = ref();

    onMounted(() => {
      quill.value = new Quill("#" + editorId);
      console.log(props.comments)
      quill.value.setContents(props.comments, 'user')
      quill.value.enable(false);
    })
  }
});
</script>
