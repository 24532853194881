<template>
  <div>
    <h2>Supplier View</h2>
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-6">
          <Form
            :validation-schema="validationSchema"
            :initial-values="initialValues"
            @submit="[handleSupplierFormAccept, handlesupplierFormDecline]"
            as="div"
            id="supplier_form"
            class="mb-10"
          >
            <div id="rfqNoWrapper" class="mb-5">
              <label for="rfqNo" class="required form-label">{{ translate('rfq.rfqNo') }}</label>
              <Field v-model="rfqNo" name="rfqNo" v-slot="{ field }">
                <input v-bind="field" type="text" class="form-control form-control-solid" />
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="rfqNo" />
                </div>
              </div>
            </div>

            <div id="rfqClientNameWrapper" class="mb-5">
              <label
                for="rfqClientName"
                class="required form-label"
              >{{ translate('rfq.supplierName') }}</label>
              <Field v-model="rfqClientName" name="rfqClientName" v-slot="{ field }">
                <input v-bind="field" type="text" class="form-control form-control-solid" />
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="rfqClientName" />
                </div>
              </div>
            </div>

            <div id="rfqClientEmailWrapper" class="mb-5">
              <label
                for="rfqClientEmail"
                class="required form-label"
              >{{ translate('rfq.supplierEmail') }}</label>
              <Field v-model="rfqClientEmail" name="rfqClientEmail" v-slot="{ field }">
                <input v-bind="field" type="text" class="form-control form-control-solid" />
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="rfqClientEmail" />
                </div>
              </div>
            </div>

            <div id="rfqDescriptionWrapper" class="mb-5">
              <label
                for="rfqDescription"
                class="required form-label"
              >{{ translate('rfq.rfqDescription') }}</label>
              <Field v-model="rfqDescription" name="rfqDescription" v-slot="{ field }">
                <textarea v-bind="field" type="text" class="form-control form-control-solid"></textarea>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="rfqDescription" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-end mb-5">
              <div class="dropzone-area text-center w-75">
                <div
                  class="dropzone d-flex align-items-center justify-content-center"
                  id="kt_dropzone_rfq"
                  style="height: 200px"
                >
                  <!--begin::Message-->
                  <div class="needsclick d-inline-block text-center dz-default dz-message">
                    <!--begin::Icon-->
                    <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                    <!--end::Icon-->

                    <!--begin::Info-->

                    <h3
                      class="fs-5 fw-bolder text-gray-900 mb-1"
                    >{{ translate('rfq.responses.dropboxMsg') }}</h3>

                    <!--end::Info-->
                  </div>
                </div>
              </div>
              <div class>
                <h5>{{ translate('rfq.uploadedDoc') }} ({{ rfqDocs.length }})</h5>

                <div
                  class="border border-1 border-dark border-dashed min-h-200px mh-200px overflow-auto"
                >
                  <a
                    class="d-block px-3 mt-3"
                    target="_blank"
                    rel="noopener"
                    :href="doc.src"
                    v-for="doc in rfqDocs"
                    :key="doc.id"
                  >
                    <inline-svg src="media/icons/duotone/General/Clip.svg" class="me-2"></inline-svg>
                    <span>{{ doc.name }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-center gap-2">
              <button
                @click="handleSupplierFormAccept"
                class="btn btn-primary btn-lg"
                type="button"
                data-kt-element="send"
              >{{ translate('rfq.responses.submitOffer') }}</button>
              <button
                @click="handlesupplierFormDecline"
                class="btn btn-danger btn-lg"
                type="button"
                data-kt-element="send"
              >{{ translate('rfq.responses.declineOffer') }}</button>
            </div>
          </Form>
        </div>
        <div class="col-6 d-flex flex-column">
          <AddComment
            widget-classes="shadow-sm rounded mb-5"
            :user-id="currentUser.id"
            :user-name="`${currentUser.name} ${currentUser.surname}`"
            @add-comment="handleAddComment"
          ></AddComment>

          <h3 class="fw-bold">{{ translate('rfq.comments') }} ({{ sortedComments.length }})</h3>

          <div class="card">
            <div class="card-body pb-0">
              <div class="mb-7 ps-10 border-start border-5">
                <CommentList
                  v-for="comment in sortedComments"
                  :key="comment['comment_id']"
                  :user-name="comment['from']['name']"
                  :created-at="handleTimeAgo(comment['created_at'])"
                  :updated-at="comment['updated_at']"
                  :comments="comment['comments']"
                ></CommentList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table__wrapper">
      <span class="svg-icon svg-icon-2x svg-icon-info me-2">
        <inline-svg src="media/icons/duotone/Code/Info-circle.svg" />
      </span>
      <span class="fs-5">{{ translate('rfq.responses.editableOnlyFieldsMsg') }}</span>
      <TableSkeleton v-show="loading" />
      <AgGridFP
        v-show="!loading"
        id="table1"
        ag-theme="ag-theme-alpine"
        widget-classes="card-xxl-stretch mb-xl-3"
        :aggrid-data="rfqRows"
        :enable-card-header="false"
        :custom-column-defs="customColumnDefs"
        :custom-body-options="bodyOptions"
        :enable-row-group="false"
        :enable-search="false"
        :enableStatusBar="false"
      ></AgGridFP>
    </div>
    <!-- <div class="container-fluid px-0">
      <div class="row">
        <div class="col-6"></div>
      </div>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { SupplierSetup } from "./Responses";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useTimeAgo } from "@vueuse/core";

import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import AddComment from "@/components/widgets/comments/Widget1.vue";
import CommentList from "@/components/widgets/comments/Widget2.vue";


export default defineComponent({
  name: "Supplier View",
  components: {
    AgGridFP,
    AddComment,
    CommentList,
    TableSkeleton,
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const {
      handleAddComment,
      currentUser,
      handleTimeAgo,
      sortedComments,
      rfqComments,
      rfqRows,
      rfqNo,
      translate,
      rfqClientName,
      rfqClientContact,
      rfqClientEmail,
      rfqDescription,
      handleSupplierFormAccept,
      handlesupplierFormDecline,
      validationSchema,
      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      rfqDocs,
      suppliersData,
      selectedUsers,
      initialValues
    } = SupplierSetup();

    return {
      handleAddComment,
      currentUser,
      handleTimeAgo,
      useTimeAgo,
      sortedComments,
      rfqComments,
      rfqRows,
      rfqNo,
      rfqClientName,
      rfqClientContact,
      rfqClientEmail,
      translate,
      rfqDescription,
      handleSupplierFormAccept,
      handlesupplierFormDecline,
      validationSchema,
      gridApi,
      colApi,
      loading,
      bodyOptions,
      setDateFormat,
      customColumnDefs,
      componentKey,
      currentLang,
      rfqDocs,
      suppliersData,
      selectedUsers,
      initialValues
    }
  }

});
</script>